import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"

type StrDict = Record<string, string>

export function resolveUrlHashParams() {
	const paramSource: string = window.location.hash
	if (!paramSource) {
		return null
	}

	const paramDict: StrDict = Object.create(null)
	paramSource
		.substring(1)
		.split('&')
		.forEach(item => {
			const [key, val] = item.split('=')
			paramDict[key] = val
		})

	return paramDict
}

export function resolveUrlSearchParams() {
	const paramSource: string = window.location.search
	if (!paramSource) {
		return null
	}

	const paramDict: StrDict = Object.create(null)
	paramSource
		.substring(1)
		.split('&')
		.forEach(item => {
			const [key, val] = item.split('=')
			paramDict[key] = val
		})

	return paramDict
}

export function createRandomString(length: number) {
	const charset =
		'0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
	const randomValues = Array.from(
		getCrypto().getRandomValues(new Uint8Array(length))
	)
	return randomValues.map(v => charset[v % charset.length]).join('')
}

export function getCrypto() {
	//ie 11.x uses msCrypto
	return (window.crypto || (window as any).msCrypto) as Crypto
}

export function parseJwt(token) {
	var base64Url = token.split('.')[1];
	var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
		return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
	}).join(''));

	return JSON.parse(jsonPayload);
}

export function isInWxworkBrowser() {
	let ua = navigator.userAgent.toLowerCase();
	let match = ua.match(/WxWork/i)
	if (match && match.length > 0 && match[0] === "wxwork") {
		return true
	}
	return false
}

export async function copyToClipboard(text: string) {
	try {
		await navigator.clipboard.writeText(text);
	} catch (error) {
		const textArea = document.createElement("textarea");
		textArea.value = text;
		document.body.appendChild(textArea);
		textArea.focus();
		textArea.select();
		try {
			document.execCommand("copy");
		} catch (error) {
			console.error("Fallback: Oops, unable to copy", error);
		}
		document.body.removeChild(textArea);
	}
}

export function createQueryParams(params: any) {
	return Object.keys(params)
		.filter((k) => params[k] !== null && params[k] !== undefined)
		.map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
		.join('&');
}
 
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}
