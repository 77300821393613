import React from "react"
import { useTranslation } from "react-i18next"
import colorScheme from "../libs/colorScheme"
import LocalizedLink from './LocalizedLink'
import aChunhuiCompanyLogo from '../images/site-logos/a_chunhui_company_white.png'

export default function Footer({ pageContext }) {
    const { t } = useTranslation()
    // 展示中國互聯網法規要求的備案信息
    const { locale } = pageContext
    const showBeiAn = locale === 'zh-CN'
    const showGrandDongshan = locale === 'ja' || locale === 'en-US'

    return (
        <footer style={{ background: colorScheme.grey, color: colorScheme.white, fontFamily: 'sans-serif', fontSize: 14, fontWeight: 'normal' }}>
            <div
                style={{
                    margin: `0 auto`,
                    maxWidth: 1280,
                    padding: `24px 16px 8px 16px`,
                    textAlign: 'right',
                    lineHeight: 2
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ marginRight: 16, textAlign: 'left' }}>

                        <div>
                            <LocalizedLink className="plain-link" to="/about">{t('nav.about-us')}</LocalizedLink>
                        </div>
                        <div>
                            <LocalizedLink className="plain-link" to="/about/leadership/executives">{t('nav.leadership-executives')}</LocalizedLink>
                        </div>
                        <div>
                            <a className="plain-link" href="http://news.chunhuizk.com">{t('nav.news')}</a>
                        </div>
                        <div>
                            <LocalizedLink className="plain-link" to="/innovation">{t('nav.innovations')}</LocalizedLink>
                        </div>
                        <div>
                            <LocalizedLink className="plain-link" to="/employee-care">{t('nav.employee-care')}</LocalizedLink>
                        </div>
                        <div>
                            <LocalizedLink className="plain-link" to="/environmental-protection">{t('nav.environmental-protection')}</LocalizedLink>
                        </div>
                    </div>
                    <div style={{ marginRight: 16, textAlign: 'left' }}>
                        <div>
                            <LocalizedLink className="plain-link" to="/products">{t('nav.products')}</LocalizedLink>
                        </div>
                        <div>
                            <LocalizedLink className="plain-link" to="/investers">{t('nav.investers')}</LocalizedLink>
                        </div>
                        <div>
                            <LocalizedLink className="plain-link" to="/jobs/search">{t('nav.careers')}</LocalizedLink>
                        </div>
                        <div>
                            <a className="plain-link" href="http://news.chunhuizk.com/?p=419">{t('nav.cpm')}</a>
                        </div>
                        <div>
                            <LocalizedLink className="plain-link" to="/contact/request">{t('nav.contact-request')}</LocalizedLink>
                        </div>
                        <div>
                            <LocalizedLink className="plain-link" to="/contact/anonymous-report">{t('nav.anonymous-report')}</LocalizedLink>
                        </div>
                        <div>
                            <LocalizedLink className="plain-link" to="/contact/integrity-inquiry">{t('nav.integrity-inquiry')}</LocalizedLink>
                        </div>
                    </div>
                </div>
                <div>
                    {`${t('contact.tel')}: `}<a className="plain-link" href={`tel:${t('contact.tel-value')}`}>{t('contact.tel-value')}</a>
                </div>
                <div>
                    {`${t('contact.email')}: `}<a className="plain-link" href={`mailto:${t('contact.email-value')}`}>{t('contact.email-value')}</a>
                </div>
                <div>
                    <a className="plain-link" href={`mailto:${t('contact.sales-email-value')}`}>{t('contact.sales-email-value')}</a>
                </div>
                <div>
                    {`${t('contact.wechat')}: ${t('contact.wechat-value')}`}
                </div>
                <div>
                    {`${t('contact.address')}: ${t('contact.address-value')}`}
                </div>

                <div className="text-right inline">

                    <img src={aChunhuiCompanyLogo} style={{ height: 18, opacity: 0.5 }} className="inline mt-4"/>
                </div>


                <div style={{ textAlign: 'center', fontWeight: 'normal', marginTop: 32 }}>
                    {t('site.name-abbr')} © {new Date().getFullYear()}
                </div>

                {showBeiAn && (
                    <div style={{ textAlign: 'center', fontWeight: 'normal', display: 'flex', flexDirection: 'column' }}>
                        <div>
                            <a href="https://beian.miit.gov.cn" className="plain-link" style={{ color: "#fff" }}>浙ICP备18051210号</a>
                        </div>
                        <div>
                            <a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33060402001077" className="plain-link" style={{ color: "#fff" }}>浙公网安备33060402001077号</a>
                        </div>
                        <div>
                            <span>东山精神，爱与担当：</span><a href="https://www.granddongshan.com" className="plain-link" style={{ color: "#fff" }}>东山大观</a>
                        </div>
                    </div>
                )}

                {
                    showGrandDongshan && (
                        <div style={{ textAlign: 'center', fontWeight: 'normal', display: 'flex', flexDirection: 'column' }}>

                            <div>
                                <span>Dongshan Sprit: </span><a href={`https://www.granddongshan.com/${locale.slice(0, 2)}`} className="plain-link" style={{ color: "#fff" }}>Grand Dongshan</a>
                            </div>
                        </div>
                    )
                }


            </div>
        </footer>
    )
}