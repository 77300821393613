import React, { ComponentPropsWithRef } from "react"
import { Link } from "gatsby"
import { languageConfig } from "../i18n/config"
import { useTranslation } from 'react-i18next'
import { useLocation } from "@reach/router"

interface LocalizedLinkProps extends ComponentPropsWithRef<any> {
  to?: string;
  toLocale?: string;
}

// Use the globally available context to choose the right path
const LocalizedLink = ({ to, toLocale, state, ...props }: LocalizedLinkProps) => {
  const { i18n } = useTranslation()
  const location = useLocation();
  const linkState = { prevUrl: location ? location.href : "/", ...state }

  if (toLocale) {
    const languages = Object.keys(languageConfig)
    const languagePaths = languages.map(language => languageConfig[language].path)
    const currentPath = location.pathname

    if (toLocale === i18n.language) {
      return (
        <Link {...props} to={`${currentPath}`} state={linkState} />
      )
    }

    if (languages.indexOf(toLocale) >= 0) {
      const paths = currentPath.split('/')

      var basePath = currentPath

      if (paths.length >= 2) {
        if (languagePaths.indexOf(paths[1]) >= 0) {
          basePath = `/${paths.slice(2).join('/')}`
        }
      }

      if (toLocale === 'zh-CN') {
        return (
          <Link {...props} to={`${basePath}?lng=zh-CN`} state={linkState} />
        )
      } else {
        const prefix = languageConfig[toLocale].path
        return (
          <Link {...props} to={`/${prefix}${basePath}`} state={linkState} />
        )
      }
    }
  }

  const locale = i18n.language

  const isIndex = to === `/`
  const isHttpLink = (to ? to : '/').indexOf('http') === 0

  // If it's the default language, don't do anything
  // If it's another language, add the "path"
  // However, if the homepage/index page is linked don't add the "to"
  // Because otherwise this would add a trailing slash
  const path = (languageConfig[locale] === undefined || languageConfig[locale].default || isHttpLink)
    ? (to ? to : '/')
    : `/${languageConfig[locale].path}${isIndex ? `` : `${to}`}`


  return (
    <Link {...props} to={path} state={linkState} />
  )
}

export default LocalizedLink