import { User as UserIcon, Globe as GlobeIcon, Menu as MenuIcon, UserCheck as UserCheckIcon, AtSignIcon } from 'lucide-react';
import React, { useEffect, useState } from "react"
import colors from '../libs/colorScheme'
import SiteLogo from './SiteLogo'
import Link from './LocalizedLink'
import { useTranslation } from 'react-i18next'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useUser } from '../hooks/useUser';

import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from "./ui/context-menu"


const Header = (props) => {
  const { pageContext } = props
  const { basicUserInfo } = useUser()
  const { t, i18n } = useTranslation()
  const [languagePanelShow, setLanguagePanelVisible] = useState(false)
  const [navigationPanelShow, setNavigationPanelVisible] = useState(false)
  const { localizedPath } = pageContext

  const languagePanelShowClass = languagePanelShow ? 'show' : ''
  const navigationPanelShowClass = navigationPanelShow ? 'show' : ''

  useEffect(() => {
    setLanguagePanelVisible(false)
    setNavigationPanelVisible(false)

  }, [localizedPath])

  const toggleLanguagePanel = () => {
    setNavigationPanelVisible(false)
    setLanguagePanelVisible(!languagePanelShow)
  }

  const toggleNavigationPanel = () => {
    setLanguagePanelVisible(false)
    setNavigationPanelVisible(!navigationPanelShow)
  }

  return (
    <header
      style={{
        background: colors.white,
        borderTop: '4px solid',
        borderColor: colors.green,
      }}
    >
      <div className={`choose-language-panel ${languagePanelShowClass} align-middle`}>
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 1280,
            textAlign: 'right',
            lineHeight: 2
          }}>
          <div style={{ padding: 16 }}>
            <span style={{ color: "#fff", fontSize: 28 }}>{t('settings.choose-language')}</span>
            <div className={`flex flex-col justify-items-start`}>
              <div>
                <Link toLocale="zh-CN" style={{ color: "#ffffff" }}>中文</Link>
              </div>
              <div>
                <Link toLocale="zh-HK">繁體中文</Link>
              </div>
              <div>
                <Link toLocale="ja">日本語</Link>
              </div>
              <div>
                <Link toLocale="en-US">English</Link>
              </div>
              <div>
                <Link toLocale="ru-RU">Русский</Link>
              </div>
              <div>
                <Link toLocale="es-ES">Español</Link>
              </div>
              <div>
                <Link toLocale="ko">한국어</Link>
              </div>
              <div>
                <Link toLocale="fr-FR">Français</Link>
              </div>
              <div>
                <Link toLocale="de-DE">Deutsch</Link>
              </div>
              <div>
                <Link toLocale="it-IT">Italiano</Link>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className={`navigation-panel ${navigationPanelShowClass} align-middle`}>
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 1280,
            padding: `24px 16px 8px 16px`,
            textAlign: 'right',
            lineHeight: 2
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ marginRight: 16, textAlign: 'left' }}>
              <div>
                <Link className="topnav-link" to="/about">{t('nav.about-us')}</Link>
              </div>
              <div>
                <Link className="topnav-link" to="/about/leadership/executives">{t('nav.leadership-executives')}</Link>
              </div>
              <div>
                <a className="topnav-link" href="http://news.chunhuizk.com">{t('nav.news')}</a>
              </div>
              <div>
                <Link className="topnav-link" to="/innovation">{t('nav.innovations')}</Link>
              </div>
              <div>
                <Link className="topnav-link" to="/employee-care">{t('nav.employee-care')}</Link>
              </div>
              <div>
                <Link className="topnav-link" to="/environmental-protection">{t('nav.environmental-protection')}</Link>
              </div>
            </div>
            <div style={{ marginRight: 16, textAlign: 'left' }}>
              <div>
                <Link className="topnav-link" to="/products">{t('nav.products')}</Link>
              </div>
              <div>
                <a className="topnav-link" href="http://news.chunhuizk.com/?p=419">{t('nav.cpm')}</a>
              </div>
              <div>
                <Link className="topnav-link" to="/investers">{t('nav.investers')}</Link>
              </div>
              <div>
                <Link className="topnav-link" to="/jobs/search">{t('nav.careers')}</Link>
              </div>
              <div>
                <Link className="topnav-link" to="/contact/request">{t('nav.contact-request')}</Link>
              </div>
              <div>
                <Link className="topnav-link" to="/contact/anonymous-report">{t('nav.anonymous-report')}</Link>
              </div>
              <div>
                <Link className="topnav-link" to="/contact/integrity-inquiry">{t('nav.integrity-inquiry')}</Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="header-nav">
        <div>
          <ContextMenu>
            <ContextMenuTrigger>
              <div className={(i18n.language === 'zh-CN' || i18n.language === 'zh-HK') ? "h-12" : "h-8"}>
                <Link to="/">
                  <SiteLogo />
                </Link>
              </div>
            </ContextMenuTrigger>
            <ContextMenuContent>
              <ContextMenuItem>...</ContextMenuItem>
              <ContextMenuItem>
                <Link to="/contact/request">{t('nav.contact-us')}</Link>
              </ContextMenuItem>
            </ContextMenuContent>
          </ContextMenu>
        </div>

        <div className="site-nav">
          <ul>
            <li>
              <div className="space-x-2 hidden md:flex">
                <Link
                  className="header-nav-item"
                  to="/products"
                  style={{
                    color: "#4d4d4d",
                    fontWeight: 'bold',
                    textDecoration: `none`,
                    alignSelf: 'center'
                  }}
                >
                  <span>{t('products.title')}</span>
                </Link>
                <Link
                  className="header-nav-item"
                  to="/investers"
                  style={{
                    color: "#4d4d4d",
                    fontWeight: 'bold',
                    textDecoration: `none`,
                    alignSelf: 'center'
                  }}
                >
                  <span>{t('nav.investers')}</span>
                </Link>
                <a
                  className="header-nav-item"
                  href="http://news.chunhuizk.com/?p=438"
                  style={{
                    color: "#4d4d4d",
                    fontWeight: 'bold',
                    textDecoration: `none`,
                    alignSelf: 'center'
                  }}
                >
                  <span className='hidden md:flex'>{t('nav.contact-us')}</span>
                  <AtSignIcon className='text-inline md:hidden' color="#1F7000" size={28} />
                </a>
              </div>
            </li>
            <li className='ml-3'>
              <button aria-label="Toggle Language Panel" onClick={() => toggleLanguagePanel()}>
                {/* 国际化i18n更换显示语言 */}
                <GlobeIcon color="#1F7000" size={28} />
              </button>
            </li>
            <li>
              {basicUserInfo.userid ? (
                <DropdownMenu.Root>
                  <DropdownMenu.Trigger asChild>
                    <button aria-label="User Profile" style={{ marginLeft: 8, cursor: "pointer" }}>
                      <UserCheckIcon color="#1F7000" size={28} />
                    </button>
                  </DropdownMenu.Trigger>

                  <DropdownMenu.Portal>
                    <DropdownMenu.Content className="DropdownMenuContent" sideOffset={5}>
                      <DropdownMenu.Item className="DropdownMenuItem">
                        <Link to="/logout">
                          {t('nav.logout')}
                        </Link>
                      </DropdownMenu.Item>
                    </DropdownMenu.Content>
                  </DropdownMenu.Portal>
                </DropdownMenu.Root>
              ) : (

                <Link to="/login">
                  <button aria-label="Login" style={{ marginLeft: 8 }}>
                    <UserIcon color="#1F7000" size={28} />
                  </button>
                </Link>
              )}
            </li>
            <li>
              <button aria-label="Toggle Navigation Panel" onClick={() => toggleNavigationPanel()} style={{ marginLeft: 8 }}>
                <MenuIcon color="#1F7000" size={28} />
              </button>
            </li>
          </ul>
        </div>
      </div>
    </header>)
}

export default Header
