// import * as Sentry from "@sentry/gatsby";

import { useCallback, useEffect, useState } from 'react';
import { isInWxworkBrowser, parseJwt, createQueryParams, resolveUrlHashParams, resolveUrlSearchParams } from '../libs/utils';
import { useHasMounted } from './useHasMounted';
import { useLocation, navigate } from "@reach/router"

export interface IBasicUserInfo {
    userid?: string
    corpid?: string
    name?: string
    email?: string
    company?: string
    phone?: string
}

const LOCAL_USER_IDTOKEN_KEY = "LOCAL_USER_IDTOKEN"
const LOGIN_SUCCESS_REDIRECT_PATHNAME_KEY = "LOGIN_SUCCESS_REDIRECT_PATHNAME"

interface UseUserConfig {
    wxworkScanLoginEndpoint?: string,
    skipLogin?: boolean;
    forceLogin?: boolean
}

let isFromLoginCallback = false

export function useUser(config: UseUserConfig = {}) {
    const hasMounted = useHasMounted()

    const { skipLogin, forceLogin } = config

    const wxworkScanLoginEndpoint = config.wxworkScanLoginEndpoint ? config.wxworkScanLoginEndpoint : "https://scf.chunhuizk.com/PASSKEY"

    const { pathname, search } = useLocation();
    const [idToken, setIdToken] = useState<string | undefined>(undefined)
    const [basicUserInfo, setBasicUserInfo] = useState<IBasicUserInfo>({})

    const loginSuccessRedirect = () => {
        console.log("loginSuccessRedirect")
        const targetAsPath = localStorage.getItem(LOGIN_SUCCESS_REDIRECT_PATHNAME_KEY)

        if (targetAsPath === null) {
            navigate("/")
        }

        if (targetAsPath && targetAsPath !== pathname) {
            console.log("targetAsPath:", targetAsPath)
            console.log("pathname:", pathname)

            localStorage.removeItem(LOGIN_SUCCESS_REDIRECT_PATHNAME_KEY)
            navigate(targetAsPath)
        }
    }


    const redirectToPassKeyLogin = (redirect_url: string) => {
        console.log("func::redirectToPassKeyLogin")

        if (isInWxworkBrowser()) {
            // 如果是在企业微信环境，使用春晖智控企业微信Passkey应用登入
            if (sessionStorage.getItem("isFromLoginCallback") === "true" || isFromLoginCallback) { return }

            console.log("isInWxworkBrowser::redirect to wxwork oauth login")
            let params_obj = {
                redirect_url: redirect_url,
                t: new Date().getTime()
            }
            let params = createQueryParams(params_obj)
            const toUrl = `${wxworkScanLoginEndpoint}?${params}`
            console.log("NavigateToUrl:", toUrl)
            navigate(toUrl)
            return
        } else {
            // 如果是在浏览器环境，使用企业微信新版扫码登入，使用春晖智控企业微信Passkey应用登入
            console.log("redirect to wxwork scan qr login")
            let params_obj = {
                redirect_url: redirect_url,
                wwlogin_sso: true, // 企业微信扫码登入标记
            }
            let params = createQueryParams(params_obj)

            const toUrl = `${wxworkScanLoginEndpoint}?${params}`
            console.log("NavigateToUrl:", toUrl)
            navigate(toUrl)

            return
        }

    }

    useEffect(() => {
        if (idToken) {
            const parseTokenResult = parseJwt(idToken)
            const userInfo = {
                userid: parseTokenResult.userid,
                corpid: parseTokenResult.corpid,
                name: parseTokenResult.name,
                email: parseTokenResult.email,
                company: parseTokenResult.company ? parseTokenResult.company :
                    (parseTokenResult.email && parseTokenResult.email.indexOf('chunhuizk.com') > 0) ? "春晖智控" : "春晖",
                phone: parseTokenResult.phone_number ? parseTokenResult.phone_number : parseTokenResult.mobile ? parseTokenResult.mobile : undefined
            }
            setBasicUserInfo(userInfo)

            // Sentry设置用户，追踪上报错误
            // Sentry.setUser(userInfo)
        }
    }, [idToken])

    useEffect(() => {

        (async () => {
            if (hasMounted && pathname) {
                const skipPathPrefix = ["/callback", "/logout", "/login", "/404"]
                const wxworkAutoLoginPathPrefix = ["/products"]

                const hashParams = resolveUrlHashParams()
                const searchParams = resolveUrlSearchParams()

                if (searchParams && searchParams.from_passkey) {
                    sessionStorage.setItem("isFromLoginCallback", "true")
                    isFromLoginCallback = true
                    console.log("sessionStorageSetItem:isFromLoginCallback:", "true")
                }

                console.log(hashParams, searchParams, pathname)

                const redirect_url = (process.env.NODE_ENV === 'production') ? "https://www.chunhuizk.com/callback/" : "http://localhost:3000/callback/"

                if (skipPathPrefix.some((prefix) => pathname.startsWith(prefix)) === false) {
                    localStorage.setItem(LOGIN_SUCCESS_REDIRECT_PATHNAME_KEY, pathname)
                }

                if (pathname.indexOf("/callback") === 0 && hashParams && hashParams.id_token) {
                    console.log("login callback page, received id_token:", hashParams.id_token)
                    console.log("search: ", searchParams)
                    /*
                    *在本地缓存用户的IDToken
                    */
                    localStorage.setItem(LOCAL_USER_IDTOKEN_KEY, hashParams.id_token)
                    setIdToken(hashParams.id_token)

                    loginSuccessRedirect()
                } else {

                    if (skipPathPrefix.some((prefix) => pathname.startsWith(prefix)) && forceLogin === false) {
                        console.log("Skip Login, reason is skipPathPrefix:", pathname)
                        return
                    }
                    console.log("process user login")

                    const userIdTokenFromLocal = localStorage.getItem(LOCAL_USER_IDTOKEN_KEY);


                    if (userIdTokenFromLocal) {
                        // 如果本地缓存有IDToken，且IDToken有效，则使用本地缓存的IDToken
                        const parseTokenResult = parseJwt(userIdTokenFromLocal)

                        const tokenIsValid = (parseTokenResult.exp * 1000) > new Date().getTime()
                        if (tokenIsValid) {
                            setIdToken(userIdTokenFromLocal)
                        } else {
                            if (forceLogin || isInWxworkBrowser()) {
                                redirectToPassKeyLogin(redirect_url);
                                return
                            }
                        }
                    } else {
                        if (skipLogin) {
                            console.log("useUser::config=SKIP LOGIN")
                            return
                        }

                        if (forceLogin) {
                            redirectToPassKeyLogin(redirect_url);
                            return
                        }

                        if (isInWxworkBrowser()) {
                            // 如果是在企业微信环境，进入部分场景例如产品中心，使用春晖智控企业微信Passkey应用登入
                            if (wxworkAutoLoginPathPrefix.some((prefix) => pathname.startsWith(prefix))) {
                                redirectToPassKeyLogin(redirect_url);
                            }
                            return
                        }
                    }
                }
            }
        })()

    }, [hasMounted, pathname])

    return { idToken, basicUserInfo };
}