import i18next from 'i18next';
import { initReactI18next } from "react-i18next"

i18next
    .use(initReactI18next)
    .init({
        fallbackLng: 'zh-CN',
        resources: {
            'zh-CN': {
                translations: require('../locales/zh_CN.json')
            },
            'zh-HK': {
                translations: require('../locales/zh_Hant_HK.json')
            },
            'en-US': {
                translations: require('../locales/en.json')
            },
            'ru-RU': {
                translations: require('../locales/ru.json')
            },
            'ko': {
                translations: require('../locales/ko.json')
            },
            'ja': {
                translations: require('../locales/ja.json')
            },
            'de-DE': {
                translations: require('../locales/de.json')
            },
            'es-ES': {
                translations: require('../locales/es.json')
            },
            'fr-FR': {
                translations: require('../locales/fr.json')
            },
            'it-IT': {
                translations: require('../locales/it.json')
            },
        },
        ns: ['translations'],
        defaultNS: 'translations',
        returnObjects: true,
        debug: false,
        interpolation: {
            escapeValue: false, // not needed for react!!
        },
        react: {
            useSuspense: true,
        },
        detection: {
            // order and from where user language should be detected
            order: ['querystring', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'subdomain'],

            // keys or params to lookup language from
            lookupQuerystring: 'lng',
            lookupLocalStorage: 'i18nextLng',
            lookupSessionStorage: 'i18nextLng',
            lookupFromPathIndex: 0,
            lookupFromSubdomainIndex: 0,

            // cache user language on
            caches: ['localStorage', 'sessionStorage'],
            // excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

            // optional expire and domain for set cookie
            // cookieMinutes: 10,
            // cookieDomain: 'myDomain',

            // optional htmlTag with lang attribute, the default is:
            // htmlTag: document.documentElement,

            // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
            // cookieOptions: { path: '/', sameSite: 'strict' },

            // optional conversion function to use to modify the detected language code
            // convertDetectedLanguage: 'Iso15897',
            // convertDetectedLanguage: (lng) => lng.replace('-', '_')
        }
    });

i18next.languages = ['zh-CN', 'zh-HK', 'en-US', 'ru-RU', 'ko', 'ja', 'de-DE', 'es-ES', 'fr-FR', 'it-IT'];

export const languageConfig = {
    'zh-CN': {
        default: true,
        path: `zh`,
        locale: `zh-CN`,
        dateFormat: `YYYY/MM/DD`,
    },
    'zh-HK': {
        path: `zh-traditional`,
        locale: `zh-HK`,
        dateFormat: `YYYY/MM/DD`,
    },
    'en-US': {
        path: `en`,
        locale: `en-US`,
        dateFormat: `YYYY/MM/DD`,
    },
    'ru-RU': {
        path: `ru`,
        locale: `ru-RU`,
        dateFormat: `YYYY/MM/DD`,
    },
    'ko': {
        path: `ko`,
        locale: `ko`,
        dateFormat: `YYYY/MM/DD`,
    },
    'ja': {
        path: `ja`,
        locale: `ja`,
        dateFormat: `YYYY/MM/DD`,
    },
    'de-DE': {
        path: `de`,
        locale: `de-DE`,
        dateFormat: `YYYY/MM/DD`,
    },
    'es-ES': {
        path: `es`,
        locale: `es-ES`,
        dateFormat: `YYYY/MM/DD`,
    },
    'fr-FR': {
        path: `fr`,
        locale: `fr-FR`,
        dateFormat: `YYYY/MM/DD`,
    },
    'it-IT': {
        path: `it`,
        locale: `it-IT`,
        dateFormat: `YYYY/MM/DD`,
    },
}

export default i18next;